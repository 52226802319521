import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiBugLine, RiSkullLine } from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout className="not-found-page">
    <Seo title="Page not found" />
    <div
      className="wrapper"
      style={{
        textAlign: "center",
      }}
    >
      <header>
        <RiSkullLine
          style={{
            fontSize: "256px",
            color: "var(--primary-color)",
          }}
        />
        <h1>Page Not Found</h1>
      </header>
      {/*<Link to="/" className="button">*/}
      {/*  <RiArrowLeftSLine className="icon -left" />*/}
      {/*  Back to Homepage*/}
      {/*</Link>*/}
      {/*<Link to="/contact" className="button -outline">*/}
      {/*  Report this <RiBugLine className="icon -right" />*/}
      {/*</Link>*/}
    </div>
  </Layout>
)

export default NotFound
